import { Form, Select } from 'antd';
import React, { Children, CSSProperties, ReactNode, useState } from 'react';
import { ISelectOption } from '../lib/@Types';
import SelectDropdown from './icons/SelectDropdown';

export const FloatingInputLabel = ({
  children,
  label,
  value,
  style,
  onClick,
}: {
  children: ReactNode;
  label?: string;
  value?: string;
  style?: CSSProperties;
  onClick?: () => void;
}) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  return (
    <>
      <div
        className={`float-label ${!label ? 'no-label' : ''}`}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        style={style}
      >
        {Children.map<ReactNode, ReactNode>(children, (child) => {
          if (React.isValidElement(child)) {
            let clonedChildren = React.cloneElement(child, {
              className: `input-float ${child.props.className || ''}`,
            } as any);

            if (Array.isArray(child.props.children)) {
              clonedChildren = React.cloneElement(child, {
                suffixIcon: <SelectDropdown />,
                style: { width: '100%' },
              } as any);
            }
            return clonedChildren;
          }
          return null;
        })}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={labelClass} role="presentation" onClick={onClick}>
          {label}
        </label>
      </div>
    </>
  );
};

interface IProps {
  label: string;
  value?: number | string;
  style?: CSSProperties;
  className?: string;
  name?: string;
  initialValue?: string;
  imgClassName?: string;
  options: ISelectOption[];
  onChange?: (value: string | number, option) => void;
}

const { Option } = Select;

export const SelectFloatingLabel = ({
  label,
  value,
  style,
  onChange,
  options,
  name,
  initialValue,
  imgClassName = 'shadow-xs',
  className = 'uppercase floating-form-item',
}: IProps) => (
  <FloatingInputLabel label={label} value={value as string} style={style}>
    <Form.Item name={name} className="form-item" initialValue={initialValue}>
      <Select
        size="large"
        className={className}
        onChange={onChange}
        value={value}
        suffixIcon={SelectDropdown}
      >
        {options.map((item) => (
          <Option
            className="uppercase"
            key={item.value}
            value={item.value}
            disabled={item.disabled}
          >
            {item.icon && (
              <img
                src={item.icon}
                height={18}
                width={18}
                style={{ marginTop: '-3px', marginRight: '10px' }}
                alt={`${item.name} chain`}
                className={imgClassName}
              />
            )}
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  </FloatingInputLabel>
);
