import { Button, Col, Form, InputNumber, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { allNftDataState, sorterQueryRecoilState } from '../../recoil/atoms';
import { FixedDefaultQueryOrder, Sorter, Sorters } from '../../recoil/models';
import { storageService } from '../../lib/db/StorageService';
import { isOnMobile, sleep } from '../../lib/Utils';
import RadioSelector from './RadioSelector';
import ModalWrapper from './ModalWrapper';
import { FloatingInputLabel } from '../FloatingInputLabel';
import { SupportedFilterChains } from '../../lib/models/NftModel';
import { IFilters, IRadioOption } from '../../lib/@Types';
import { FILTERCHAINS } from '../../lib/constants';
import FilterChainSelector from './FilterChainSelector';

const defaultOptions: IRadioOption[] = [
  { label: 'Default', value: 'default' },
  { label: 'Recently Added', value: 'recent' },
  { label: 'Lowest Price', value: 'cheapest' },
  { label: 'Highest Price', value: 'expensive' },
  { label: 'Most Upvotes', value: 'upvotesCount' },
];

export const FilterModal = ({
  visible,
  options,
  filters,
  onCancel,
  onFilter,
}: {
  visible: boolean;
  options?: IRadioOption[];
  filters?: IFilters;
  onCancel?: () => void;
  onFilter?: (sorterQuery: Sorter) => void;
}) => {
  const { t } = useTranslation();
  const [nftDataList, setNftDataList] = useRecoilState(allNftDataState);
  const [querySorterLoading, setQuerySorterLoading] = useRecoilState(
    sorterQueryRecoilState,
  );

  const [formState, setFormState] = useState<IFilters>({
    minPrice: filters?.minPrice || querySorterLoading.queryOrder?.minPrice,
    maxPrice: filters?.maxPrice || querySorterLoading.queryOrder?.maxPrice,
    name:
      filters?.name ||
      querySorterLoading.queryOrder?.name ||
      FixedDefaultQueryOrder.name,
    chain: filters?.chain || querySorterLoading.queryOrder?.chain || 'all',
  });

  const [filterModalForm] = Form.useForm();

  const clearForm = () => {
    setFormState({
      maxPrice: null,
      minPrice: null,
      name: FixedDefaultQueryOrder.name,
      chain: 'all',
    });
    filterModalForm.resetFields();
  };

  useEffect(() => {
    if (!querySorterLoading.queryOrder) {
      clearForm();
    }
  }, [querySorterLoading.queryOrder]);

  const onSubmit = async () => {
    const fieldSorter = Sorters[formState.name];

    const value = formState?.chain || querySorterLoading.queryOrder?.chain;

    formState.chain = (value === 'all' ? '' : value) as SupportedFilterChains;

    const queryOrder = { ...formState, ...fieldSorter };

    onCancel();

    if (onFilter) {
      onFilter(queryOrder);
      return;
    }

    setQuerySorterLoading({
      inLoadingState: true,
      queryOrder,
    });

    const loadedSortedNfts = await storageService.findAllNfts(
      isOnMobile() ? 8 : 12,
      '',
      queryOrder,
    );

    setNftDataList({
      ...nftDataList,
      nfts: loadedSortedNfts.nfts,
    });

    await sleep(2_000);

    setQuerySorterLoading({
      queryOrder,
      inLoadingState: false,
    });
  };

  const onChange = (changedValue = {}) => {
    setFormState((state) => ({ ...state, ...changedValue }));
  };

  return (
    <ModalWrapper
      visible={visible}
      onCancel={onCancel}
      width={331}
      height={440}
    >
      <div className="account-modal-div">
        <div className="filter-by-title">{t('Filter By')}</div>

        <Form form={filterModalForm} onFinish={onSubmit}>
          <RadioSelector
            options={options || defaultOptions}
            value={formState.name}
            onChange={(name) => onChange({ name })}
          />

          <FilterChainSelector
            style={{ marginBottom: '10px' }}
            value={formState.chain}
            onChange={(chain) =>
              onChange({ chain: chain as SupportedFilterChains })
            }
            options={FILTERCHAINS}
          />

          <Row wrap={false} gutter={[16, 16]} className="hidden-div">
            <Col>
              <FloatingInputLabel
                label={t('Min Price in BNB')}
                style={{ marginBottom: '10px' }}
                value={(formState.minPrice as unknown) as string}
              >
                <Form.Item name="minPrice" className="m-0">
                  <InputNumber
                    size="large"
                    type="number"
                    inputMode="decimal"
                    className="create-nft-input"
                    stringMode
                    min={0.0001}
                    max={10000000}
                    onChange={(value) => onChange({ minPrice: value })}
                  />
                </Form.Item>
              </FloatingInputLabel>
            </Col>
            <Col>
              <FloatingInputLabel
                label={t('Max Price in BNB')}
                style={{ marginBottom: '10px' }}
                value={(formState.maxPrice as unknown) as string}
              >
                <Form.Item name="maxPrice" className="m-0">
                  <InputNumber
                    size="large"
                    type="number"
                    inputMode="decimal"
                    className="create-nft-input"
                    stringMode
                    min={formState.minPrice}
                    max={10000000}
                    onChange={(value) => onChange({ maxPrice: value })}
                  />
                </Form.Item>
              </FloatingInputLabel>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col className="flex-1">
              <Button
                type="primary"
                className="nft-card-edit-button normal-text-15"
                htmlType="submit"
                block
              >
                {t('Apply')}
              </Button>
            </Col>
            <Col className="flex-1">
              <Button
                type="default"
                className="nft-card-edit-button bg-white shadow-xs normal-text-15 text-gray border-transparent"
                onClick={clearForm}
                block
              >
                {t('Clear')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </ModalWrapper>
  );
};
