import React from 'react';

interface Props {
  className?: string;
  width?: string;
  height?: string;
}

const CheckedIcon = ({ className, width = '16', height = '12' }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.6 6.4L1.6 4L0 5.6L5.6 12L16 1.6L14.4 0L5.6 6.4Z" fill="white" />
  </svg>
);

export default CheckedIcon;
