import { message } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { backendApiService } from '../../lib/services/BackendApiService';
import {
  canLoadMoreSearchResultState,
  searchStoreState,
} from '../../recoil/atoms';

const useResearchResults = () => {
  const { t } = useTranslation();
  const [searchResultList, setSearchResultList] = useRecoilState(
    searchStoreState,
  );

  const setShowLoadMoreForSearchResults = useSetRecoilState(
    canLoadMoreSearchResultState,
  );

  async function onLoadMoreSearchResults() {
    console.log('onLoadMoreSearchResults');
    return;
    const nextFetchSize = searchResultList.results.items.length + 8;
    setSearchResultList({
      ...searchResultList,
      searching: true,
    });

    const newLoadedSearchResults = await backendApiService.getSearchResults(
      searchResultList.lastQuery,
      nextFetchSize,
    );

    if (
      newLoadedSearchResults.items.length <=
      searchResultList.results.items.length
    ) {
      setShowLoadMoreForSearchResults(false);
      message.info(t('You have loaded all items'));

      setSearchResultList({
        ...searchResultList,
        searching: false,
      });
      return;
    }

    setSearchResultList({
      ...searchResultList,
      results: {
        collections: newLoadedSearchResults.collections,
        users: newLoadedSearchResults.users,
        items: newLoadedSearchResults.items,
      },
      searching: false,
    });
  }
  return onLoadMoreSearchResults;
};

export default useResearchResults;
