import React, { FC, CSSProperties, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FloatingInputLabel } from '../FloatingInputLabel';
import SelectDropdown from '../icons/SelectDropdown';
import { CHAINS } from '../../lib/constants';

const { Option } = Select;

interface IProps {
  style?: CSSProperties;
  value?: string;
  onChange?: (chain: string) => void;
  options?: object;
}

const FilterChainSelector: FC<IProps> = ({
  value,
  style,
  onChange,
  options,
}: IProps) => {
  const [chainValue, setChainValue] = useState('');
  const { t } = useTranslation();

  const allChainsValue = '' || 'all';

  const currentChain = chainValue === allChainsValue ? 'all' : value;

  const onChainChanged = async (chain) => {
    setChainValue(chain);

    onChange?.(chain);
  };

  return (
    <FloatingInputLabel
      label={t('Blockchain')}
      value={currentChain}
      style={style}
    >
      <Select
        size="large"
        className="uppercase floating-form-item floatable-wrapper"
        onChange={onChainChanged}
        value={currentChain}
        suffixIcon={SelectDropdown}
      >
        {Object.values(options).map((item) => (
          <Option className="uppercase" key={item.name} value={item.name}>
            <img
              src={item.icon}
              height={18}
              width={18}
              style={{ marginTop: '-3px', marginRight: '3px' }}
              alt={`${item.name} chain`}
            />
            {item.name}
          </Option>
        ))}
      </Select>
    </FloatingInputLabel>
  );
};

FilterChainSelector.defaultProps = {
  options: CHAINS,
};

export default FilterChainSelector;
