import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CheckedIcon from '../icons/CheckedIcon';

interface Props {
  value: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
  onChange?: (value: string, selected?: boolean) => void;
}

const RadioSelector: FC<Props> = ({
  value,
  disabled,
  options,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {options.map((option) => (
        <div
          key={option.value}
          role="button"
          tabIndex={0}
          onKeyDown={() => null}
          onClick={() => onChange(option.value)}
          className="flex align-center justify-between my-3"
          aria-disabled={disabled}
        >
          <div
            className={`top-trader-name ${
              option.value === value ? 'text-blue' : ''
            }`}
          >
            {t(option.label)}
          </div>
          {(option.value === value && (
            <div
              className="item-circle-approve bg-blue"
              style={{ height: '16px', width: '16px', padding: '3px' }}
            >
              <CheckedIcon />
            </div>
          )) ||
            null}
        </div>
      ))}
    </>
  );
};

RadioSelector.defaultProps = {
  disabled: false,
};

export default RadioSelector;
