import React from 'react';
import { CHAINS } from '../../lib/constants';
import { BNBIcon } from '../../lib/Resources';

type Props = {
  amount: number;
  className?: string;
  symbol?: string;
};

export const VolumeTradedUSD = ({ amount, className, symbol }: Props) => (
  <span className={className}>
    {!symbol
      ? new Intl.NumberFormat('en-US', {
          currency: 'USD',
          style: 'currency',
          maximumFractionDigits: 3,
        }).format(amount || 0)
      : `${Number(amount).toLocaleString(undefined, {
          maximumFractionDigits: 3,
        })} ${symbol}`}
  </span>
);

export const CollectionVolumeTraded = ({
  className,
  amount,
  chain,
}: {
  className: string;
  amount: number;
  chain: string;
}) => {
  const chainAsset = CHAINS[chain] || CHAINS.bsc;

  return (
    <div className="flex justify-center align-center">
      <img
        src={chainAsset.icon || BNBIcon}
        width={20}
        height={20}
        alt="Asset logo"
      />
      <span className={className} style={{ marginLeft: '5px' }}>
        {amount?.toFixed(3)}
      </span>
    </div>
  );
};
