import React from 'react';
import { List, Tabs } from 'antd';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import {
  CollectionModel,
  CollectionModelToCardData,
  NftModelToCardData,
} from '../../lib/models/NftModel';
import { NftCard } from '../NftCard';
import {
  canLoadMoreSearchResultState,
  searchStoreState,
} from '../../recoil/atoms';
import ListViewBuilder from '../ListViewBuilder';
import { CollectionCard } from '../CollectionCard';
import UserSmallCard from './UserSmallCard';

const { TabPane } = Tabs;

const SearchResultsView = ({
  onLoadMoreSearchResults,
}: {
  onLoadMoreSearchResults: () => void;
}) => {
  const { t } = useTranslation();

  const searchResultList = useRecoilValue(searchStoreState);

  const showLoadMoreForSearchResults = useRecoilValue(
    canLoadMoreSearchResultState,
  );

  const CollectionsTabComponent = () => (
    <div className="my-3">
      <ListViewBuilder
        onLoadMore={onLoadMoreSearchResults}
        showLoadMoreButton={showLoadMoreForSearchResults}
        loading={searchResultList.searching}
        items={searchResultList.results.collections}
        renderItem={(collection) => (
          <List.Item>
            <CollectionCard
              collectionCardData={CollectionModelToCardData(
                collection as CollectionModel,
              )}
            />
          </List.Item>
        )}
        showEndMessage={
          !!searchResultList.results.collections &&
          !!searchResultList.results.collections.length
        }
      />
    </div>
  );

  const ItemsTabComponent = () => (
    <div className="my-3">
      <ListViewBuilder
        items={searchResultList.results.items}
        renderItem={(item) => (
          <List.Item>
            {/* @ts-ignore */}
            <NftCard nftCardData={NftModelToCardData(item)} />
          </List.Item>
        )}
        showLoadMoreButton={showLoadMoreForSearchResults}
        loading={searchResultList.searching}
        onLoadMore={onLoadMoreSearchResults}
        showEndMessage={
          !!searchResultList.results.items &&
          !!searchResultList.results.items.length
        }
      />
    </div>
  );

  const UsersTabComponent = () => (
    <div className="my-3">
      <ListViewBuilder
        onLoadMore={onLoadMoreSearchResults}
        showLoadMoreButton={showLoadMoreForSearchResults}
        loading={searchResultList.searching}
        items={searchResultList.results.users}
        renderItem={(user) => (
          <List.Item>
            {/* @ts-ignore */}
            <UserSmallCard user={user} />
          </List.Item>
        )}
        showEndMessage={
          !!searchResultList.results.users &&
          !!searchResultList.results.users.length
        }
      />
    </div>
  );

  return (
    <div>
      <Tabs defaultActiveKey="items" className="nft-detail-tab">
        <TabPane tab={t('ITEMS')} key="items">
          <ItemsTabComponent />
        </TabPane>
        <TabPane tab={t('COLLECTIONS')} key="collection">
          <CollectionsTabComponent />
        </TabPane>
        <TabPane tab={t('USERS')} key="user">
          <UsersTabComponent />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SearchResultsView;
