import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { searchStoreState } from '../../recoil/atoms';
import { truncate } from '../../lib/Utils';

export function ExploreTitle({ inSearchMode }: { inSearchMode: boolean }) {
  const { t } = useTranslation();
  const searchResultList = useRecoilValue(searchStoreState);
  if (inSearchMode) {
    const { lastQuery } = searchResultList;
    return (
      <div className="search-title style-title">
        <span className="result-title-label">{t('Search Results for')}</span>
        <span>{lastQuery ? ` "${truncate(lastQuery, 18)}" ` : ''}</span>
      </div>
    );
  }
  return <div className="explore-title style-title">{t('Explore ⚡️')}</div>;
}
